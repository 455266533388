import React from "react";
import Header from "../../custom/Header";

function NotFoundPage() {
  return (
    <div>
      <Header />
      <h1>404 Not found</h1>
    </div>
  );
}

export default NotFoundPage;
